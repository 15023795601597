import React from 'react';
import WidgetAbout from './WidgetAbout';
import WidgetCategory from './WidgetCategory';
import WidgetTag from './WidgetTag';
import WidgetLatestPosts from './WidgetLatestPosts';
import WidgetLinks from './WidgetLinks';
import WidgetSearch from './WidgetSearch';
import config from '../../../data/SiteConfig';

const Sidebar = ({ tagList, categoryList, latestPostEdges, links }) => {
  return (
    <aside
      className={`sidebar-container width-full ${
        config.sidebarSticky ? 'height-full' : ''
      }`}
    >
		<WidgetAbout aboutBody={config.aboutBody} />

		<WidgetSearch />

		{latestPostEdges && (
		<WidgetLatestPosts latestPostEdges={latestPostEdges} />
		)}

		{categoryList && <WidgetCategory categoryList={categoryList} />}

		{tagList && <WidgetTag tagList={tagList} />}

		{links && (
		<div className="position-sticky top-zero">
			<WidgetLinks links={links} />
		</div>
		)}
    </aside>
  );
};

export default Sidebar;

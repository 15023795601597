import React from "react";
import WidgetContainer from "./WidgetContainer";
import WidgetTitle from "./WidgetTitle";
import config from "../../../data/SiteConfig";
import headshot from "../../../content/images/hilton_giesenow_cari_small.png";
import SocialLinks from "../Footer/SocialLinks";

const WidgetAbout = ({ aboutBody }) => (
  <WidgetContainer extraClass="categories-container">
    <WidgetTitle title={config.aboutWidgetTitle} />
    <div>
		<img className="image margin-right-half border-radius about-me-pic" src={headshot} alt="Headshot" />
      	{aboutBody}
    </div>

	<SocialLinks socials={config.socialLinks} color="#2196f3" />

  </WidgetContainer>
)

export default WidgetAbout;